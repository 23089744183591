import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/painel/dashboard",
      component: () => import("@/view/layout/Layout"),
      meta: {
        checkPermission: false
      },
      children: [
        {
          path: "/painel/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/painel/Dashboard.vue"),
          meta: {
            checkPermission: true,
            permission: "visualizar-relatorio"
          }
        },
        {
          path: "/painel/conta",
          name: "conta",
          component: () => import("@/view/pages/painel/conta/Conta.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-conta"
          }
        },
        {
          path: "/painel/envio",
          name: "envio",
          component: () => import("@/view/pages/painel/Envio.vue"),
          meta: {
            checkPermission: true,
            permission: "enviar-mensagem"
          }
        },
        {
          path: "/painel/automacao",
          name: "automacao",
          component: () => import("@/view/pages/painel/automacao/Index.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/chatbot/fluxos",
          name: "chatbot",
          component: () => import("@/view/pages/painel/chatbot/Fluxos.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/chatbot/menus",
          name: "chatbot-menus",
          component: () => import("@/view/pages/painel/chatbot/Menus.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/chatbot/opcoes",
          name: "chatbot-opcoes",
          component: () =>
            import("@/view/pages/painel/chatbot/Configuracoes.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/chatbot/faq",
          name: "chatbot-faq",
          component: () => import("@/view/pages/painel/chatbot/FAQ.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/chatbot/informacoes-ia",
          name: "chatbot-informacoes-ia",
          component: () =>
            import("@/view/pages/painel/chatbot/InformacoesIA.vue"),
          meta: {
            checkPermission: true,
            permission: "listar-campanha"
          }
        },
        {
          path: "/painel/automacao/cadastro-fluxo",
          name: "automacao.fluxo.cadastro",
          component: () =>
            import(
              "@/view/pages/painel/automacao/fluxo/components/CadastroFluxo.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/cadastro-consulta",
          name: "automacao.consulta.cadastro",
          component: () =>
            import(
              "@/view/pages/painel/automacao/fluxo/consultas/CadastroConsulta.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/editar-consulta",
          name: "automacao.consulta.editar",
          component: () =>
            import(
              "@/view/pages/painel/automacao/fluxo/consultas/CadastroConsulta.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/editar-fluxo",
          name: "automacao.fluxo.editar",
          component: () =>
            import(
              "@/view/pages/painel/automacao/fluxo/components/CadastroFluxo.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/cadastro-licenciada",
          name: "automacao.licenciada.cadastro",
          component: () =>
            import(
              "@/view/pages/painel/automacao/licenciada/CadastroLicenciada.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/edita-licenciada/:id",
          name: "automacao.licenciada.edita",
          component: () =>
            import(
              "@/view/pages/painel/automacao/licenciada/CadastroLicenciada.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "gerenciar-campanha"
          }
        },
        {
          path: "/painel/automacao/cadastro-template",
          name: "automacao.template.cadastro",
          component: () =>
            import(
              "@/view/pages/painel/automacao/template-whatsapp/CadastroTemplateWhatsapp.vue"
            ),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/mapeamento-perfil",
          name: "mapeamento-perfil",
          component: () =>
            import(
              "@/view/pages/painel/mapeamento-perfil/MapeamentoPerfil.vue"
            ),
          meta: {
            checkPermission: true,
            permission: "mapeamento-perfil"
          }
        },
        {
          path: "/painel/relatorio/consolidado",
          name: "relatorio-consolidado",
          component: () =>
            import("@/view/pages/painel/relatorio/Consolidado.vue"),
          meta: {
            checkPermission: true,
            permission: "visualizar-relatorio"
          }
        },
        {
          path: "/painel/relatorio/detalhado/:periodo?/:modelo?",
          name: "relatorio-detalhado",
          component: () =>
            import("@/view/pages/painel/relatorio/detalhado/Detalhado.vue"),
          meta: {
            checkPermission: true,
            permission: "visualizar-relatorio"
          }
        },
        {
          path: "/painel/nps/:aba?",
          name: "nps",
          component: () => import("@/view/pages/painel/Nps.vue"),
          meta: {
            checkPermission: true,
            permission: "visualizar-nps"
          }
        },
        {
          path: "/painel/executivo/:id",
          name: "bi",
          component: () => import("@/view/pages/painel/BI.vue")
          // meta: {
          //   checkPermission: true,
          //   permission: "visualizar-bi"
          // }
        },
        {
          path: "/painel/configuracao",
          name: "configuracao",
          component: () => import("@/view/pages/painel/Configuracao.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/features",
          name: "feature_flag",
          component: () => import("@/view/pages/painel/FeatureFlag.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/contatos",
          name: "contatos",
          component: () => import("@/view/pages/painel/Contatos.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/novidade",
          name: "novidade",
          component: () => import("@/view/pages/painel/novidade/Index.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/notificar-erros",
          name: "notificar_erros",
          component: () =>
            import("@/view/pages/painel/NotificarErrosSlack.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/recurso-ia",
          name: "recurso_ia",
          component: () => import("@/view/pages/painel/recurso-ia/Index.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/novidade/:id",
          name: "novidade.nova",
          component: () => import("@/view/pages/painel/novidade/Novidade.vue")
        },
        {
          path: "/painel/novidade/cadastro",
          name: "novidade.cadastro",
          component: () =>
            import("@/view/pages/painel/novidade/CadastroNovidade.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/novidade/editar",
          name: "novidade.editar",
          component: () =>
            import("@/view/pages/painel/novidade/CadastroNovidade.vue"),
          meta: {
            checkPermission: true,
            permission: "gerenciar-configuracao"
          }
        },
        {
          path: "/painel/agende/agenda",
          name: "agende.agenda",
          component: () =>
            import("@/view/pages/painel/agende/agenda/Index.vue"),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/automacao/cadastro-agenda",
          name: "automacao.agenda.cadastro",
          component: () =>
            import("@/view/pages/painel/agende/agenda/CadastroAgenda.vue"),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/agende/calendario",
          name: "agende.calendario",
          component: () =>
            import("@/view/pages/painel/agende/calendario/Index.vue"),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/agende/configuracoes",
          name: "agende.configuracoes",
          component: () =>
            import("@/view/pages/painel/agende/configuracoes/Index.vue"),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/relatorio/estatistica",
          name: "estatistica",
          component: () =>
            import("@/view/pages/painel/relatorio/EstatisticaSuperset.vue"),
          meta: {
            checkPermission: true,
            permission: "visualizar-relatorio"
          }
        },
        {
          path: "/painel/logs",
          name: "logs",
          component: () => import("@/view/pages/painel/Logs.vue"),
          meta: {
            checkPermission: false
          }
        },
        {
          path: "/painel/jpr/calendario",
          name: "jpr-calendario",
          component: () => import("@/view/pages/jpr/Calendario.vue"),
          meta: {
            checkPermission: false
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
          meta: {
            checkPermission: false
          }
        }
      ]
    },
    {
      path: "/painel/amostra/:modelo_campanha_id?",
      name: "painel-amostra",
      component: () =>
        import("@/view/pages/painel/painel-amostra/PainelAmostra"),
      meta: {
        checkPermission: false
      }
    },
    {
      path: "/recuperar-senha/:email/:token",
      name: "recuperar-senha",
      component: () => import("@/view/pages/painel/RecuperarSenha.vue"),
      meta: {
        checkPermission: false
      }
    },
    {
      path: "/oauth/redirect",
      name: "oauth-redirect",
      component: () => import("@/view/pages/painel/oauth/Redirect.vue"),
      meta: {
        checkPermission: false
      }
    },
    {
      path: "/painel/*",
      redirect: "/404",
      meta: {
        checkPermission: false
      }
    },
    {
      // the 404 route, when none of the above matches
      path: "/painel/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
      meta: {
        checkPermission: false
      }
    },
    {
      path: "/validar-2fa",
      name: "validar-2fa",
      component: () =>
        import("@/view/pages/auth/login_pages/AutenticacaoDoisFatores"),
      meta: {
        checkPermission: false
      }
    },
    {
      path: "/meet/:roomName",
      name: "MeetRoom",
      component: () => import("@/view/components/MeetRoom.vue"),
      props: (route) => ({
        roomName: route.params.roomName
      }),
      meta: {
        isPublic: true
      },
      beforeEnter(to, from, next) {
        const rawToken = to.params.roomName;

        if (rawToken === "end") {
          return next();
        }

        // Remove any dashes
        const cleanedToken = rawToken.replace(/-/g, "");

        if (cleanedToken.length !== 10) {
          return next({
            name: "MeetRoom",
            params: { roomName: "end" }
          });
        }

        // Create the correctly formatted token: XXX-XXXX-XXX
        const formattedToken =
          cleanedToken.substring(0, 3) +
          "-" +
          cleanedToken.substring(3, 7) +
          "-" +
          cleanedToken.substring(7);

        // If the token is not already in the correct format, redirect to the correct URL
        if (rawToken !== formattedToken) {
          return next({
            name: "MeetRoom",
            params: { roomName: formattedToken }
          });
        }
        // Otherwise, the token is valid and correctly formatted. Proceed.
        next();
      }
    }
  ]
});
